import web_dev from './web-dev.png';
import app_dev from './app_dev.png';
import digital_marketing from './digital_marketing.png';
import graphics_design from './graphics_design.jpg';
import video_editing from './video_editing.jpg';
import seo_writing from './seo_writing.jpg';

export const servicesData = [
  {
    imgSrc: web_dev,
    altText: "Web Development Icon",
    title: "Web Development",
    description: "A professionally made website will give your small business credibility. Customers trust businesses that have an established online presence – it shows professionalism, quality, safety, and stability; all things consumers want from their favorite brands!",
    link: "/service/webdevelopment"
  },
  {
    imgSrc: app_dev,
    altText: "App Development Icon",
    title: "App Development",
    description: "Return on investment (ROI) is one reason why it’s important for your business to have a mobile app, as you can make sure that you get the most out of your money by providing customers with a product or service that will help them in their daily lives and make their lives easier.",
    link: "/service/appdevelopment"
  },
  {
    imgSrc: graphics_design,
    altText: "Graphics Design Icon",
    title: "Graphics Design",
    description: "Graphic design is crucial for a company as it creates a memorable, professional image and enhances brand recognition. A well-designed logo and cohesive branding establish a strong market presence and differentiate the company from competitors.",
    link: "/service/graphicsdesign"
  },
  {
    imgSrc: digital_marketing,
    altText: "Digital Marketing Icon",
    title: "Digital Marketing",
    description: "Digital marketing is essential for a company as it expands its reach and connects with a broader audience online. By leveraging tools like social media, email campaigns, and SEO, companies can target specific demographics and engage potential customers more effectively.",
    link: "/service/digitalmarketing"
  },
  {
    imgSrc: video_editing,
    altText: "Videography Icon",
    title: "Videography",
    description: "Videography is essential for a company as it creates polished, professional content that enhances the brand's image. Well-edited videos can captivate audiences, effectively conveying the company’s message and values, and making a lasting impression.",
    link: "/service/videography"
  },
  {
    imgSrc: seo_writing,
    altText: "SEO Writing Icon",
    title: "SEO Writing",
    description: "SEO writing is crucial for a company as it enhances online visibility, driving more organic traffic to the website. Well-crafted SEO content helps improve search engine rankings, making it easier for potential customers to find the company’s products or services.",
    link: "/service/seowriting"
  }
];
