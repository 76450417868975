import React from 'react';
import Home from '../Home/home';
import Services from '../Services/services';
import Portfolio from '../Portfolio/portfolio';
// import Contact from '../Contact/contact';
import DanexGroup from '../About/about';
// import Faq from '../Faq/faq';

export default function Mainlayout() {
  return (
    <>
      <Home />
      <DanexGroup />
      <Services />
      <Portfolio />
      {/* <Faq/> */}
      {/* <Contact /> */}
      
    </>
  );
}
