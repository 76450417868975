import React from 'react';
import digitalmarketing from './service_image/digitalmarketing.jpg';
import 'animate.css';
import { Link } from 'react-router-dom';

const sharedClasses = {
  lightColor: 'text-gray-',
  lightBgColor: 'bg-white',
  hoverColor: 'hover:text-blue-500',
};

const DigitalMarketing = () => {
  return (
    <div className={`${sharedClasses.lightBgColor} min-h-screen p-4 font-inter leading-tight`}>
      <div className="max-w-7xl mx-auto">
        <header className="text-center py-8">
          <h1 className={`${sharedClasses.lightColor}800 text-4xl font-semibold mb-4`}>Digital Marketing</h1>
          <nav className={`${sharedClasses.lightColor}500 text-base mt-2`}>
            <Link to='/' className={sharedClasses.hoverColor}>Home</Link> / <span>Digital Marketing</span>
          </nav>
        </header>
        <section className="mb-8">
          <img
            src={digitalmarketing}
            alt="Digital Marketing"
            className="w-full h-auto rounded-lg shadow-lg animate__animated animate__fadeIn"
          />
        </section>
        <section className={`${sharedClasses.lightColor}700 px-4 text-lg`}>
          <div className="py-16">
            <p className="mb-4">
              At Danex Solution, we excel in providing comprehensive digital marketing services designed to help your business thrive online. Our team of experienced marketers is dedicated to creating effective strategies that drive traffic, increase engagement, and boost conversions. We leverage the latest tools and techniques to ensure that your digital presence is both impactful and measurable.
            </p>
            <p>
              We understand that each business is unique, which is why we offer customized marketing solutions tailored to your specific needs. Whether you need SEO, social media marketing, or PPC campaigns, we have the expertise to deliver results that exceed your expectations.
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center mb-8 md:space-x-8">
            <img src={digitalmarketing} alt="Our Services" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInLeft" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Our Services</h3>
              <p className="mb-4">
                We offer a comprehensive range of digital marketing services designed to help you succeed in the digital landscape. Our services include:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Search Engine Optimization (SEO)</li>
                <li>Pay-Per-Click (PPC) Advertising</li>
                <li>Social Media Marketing</li>
                <li>Content Marketing</li>
                <li>Email Marketing</li>
              </ul>
              <p>
                No matter the size or complexity of your project, our team is equipped to handle it with professionalism and expertise.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-8 md:space-x-8">
            <img src={digitalmarketing} alt="Why Choose Us" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInRight" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Why Choose Us?</h3>
              <p className="mb-4">
                We believe in delivering quality solutions that drive success for our clients. Here’s why you should choose Danex Solution:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Expertise in the Latest Digital Marketing Trends</li>
                <li>Customized Strategies</li>
                <li>Data-Driven Approach</li>
                <li>On-Time Delivery</li>
                <li>Ongoing Support and Optimization</li>
              </ul>
              <p>
                Our goal is to build long-term relationships with our clients by providing reliable and effective marketing solutions.
              </p>
            </div>
          </div>
          <div className="md:space-x-8">
            <div className='pb-16'>
              <h3 className="text-xl font-extrabold mb-2">Get in Touch</h3>
              <p className="mb-4">
                Ready to elevate your digital presence? <a href="#" className={sharedClasses.hoverColor}>Contact us</a> today to discuss your project and get a free quote! We are excited to work with you and help your business grow.
              </p>
              <p>
                At Danex Solution, we are passionate about digital marketing and are dedicated to delivering exceptional results. Let us help you create a digital strategy that not only looks great but also performs exceptionally well.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DigitalMarketing;
