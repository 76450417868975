import React, { useState, useEffect, useRef } from 'react';
import './about.css'
import start from './start.png'
import scale from './scale.png'
import streamline from './streamline.png'
import about_img from './about-img.png'

const sharedClasses = {
  textZinc: 'text-zinc-600',
  textZincHover: 'hover:text-blue-500',
  textZincDark: 'text-zinc-800',
};

const Feature = ({ iconSrc, title, description }) => {
  return (
    <div className="flex flex-col items-center md:items-start">
      <img src={iconSrc} alt="Feature Icon" className="w-12 h-12 mb-4" />
      <h3 className={`text-lg font-bold ${sharedClasses.textZincDark}`}>{title}</h3>
      <p className={`mt-2 ${sharedClasses.textZinc}`}>{description}</p>
    </div>
  );
};

const DanexGroup = () => {
  const [isVisible, setIsVisible] = useState(false); // State for section visibility
  const sectionRef = useRef(null); // Reference to the section div

  // Use the Intersection Observer API to detect when the section is visible in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set the section as visible when it appears in the viewport
          observer.disconnect(); // Stop observing once the section is visible
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => observer.disconnect(); // Clean up the observer on component unmount
  }, []);

  return (
    <div
      ref={sectionRef} // Attach the ref to the section div
      className={`flex flex-col justify-space-between items-center p-12 bg-white about ${isVisible ? 'animate-fadeInUp' : 'opacity-0'}`} // Apply animation if visible
      id='about'
    >
      <div className="flex flex-col md:flex-row items-center mt-8 space-y-8 md:space-y-0 md:space-x-8 about-items">
        <img
          src={about_img}
          alt="Technology Illustration"
          className="object-cover rounded-lg about-image animate-fadeIn"
        />
        <div className="text-center md:text-left w-half about-texts">
          <h2 className={`text-2xl font-bold w-text500 ${sharedClasses.textZincDark}`}>
            We live in earth and breathe technology.
          </h2>
          <p className={`mt-4 ${sharedClasses.textZinc}`}>
            We all are surrounded by technologies and everyone on the planet uses the internet. So why not take your business online?
          </p>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8 about-texts">
            <Feature
              iconSrc={start}
              title="Start"
              description="If you have an idea, we can help you get started and launch to the market in days."
            />
            <Feature
              iconSrc={scale}
              title="Scale"
              description="If you have an existing small business, we can help you scale it worldwide."
            />
            <Feature
              iconSrc={streamline}
              title="Streamline"
              description="If you are a big company, we can help you streamline the processes and growth in the long term."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DanexGroup;
