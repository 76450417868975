import React from 'react';

const sharedClasses = {
  container: 'max-w-4xl mx-auto p-12 py-20',
  nav: 'text-right mb-4',
  heading: 'text-3xl font-bold mb-6',
  section: 'space-y-6',
  subHeading: 'text-2xl font-bold',
  paragraph: 'mb-4',
};

const TermsAndConditions = () => {
  return (
    <div className={sharedClasses.container}>
      <nav className={sharedClasses.nav}>
        <a href="/" className="text-orange-500">Home</a> / <span>Terms and condition</span>
      </nav>
      <h1 className={sharedClasses.heading}>Terms and condition</h1>
      <div className={sharedClasses.section}>
        <TermsSection
          title="1. Introduction:"
          content="These Terms and Conditions (the 'Terms') govern your access to and use of the services provided by Danex Solution (the 'Company'), a digital marketing agency. By accessing or using our services, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not access or use our services."
        />
        <TermsSection
          title="2. Binding Agreement:"
          content="These Terms constitute a binding agreement (the 'Agreement') between you and Danex Solution. By using our services, you accept these Terms and agree to use Danex Solution's services at your own risk. The laws of Nepal govern this Agreement, and by agreeing to these Terms, you submit to the exclusive jurisdiction of Nepal courts."
        />
        <TermsSection
          title="3. Definitions:"
          content="These Terms constitute a binding agreement (the 'Agreement') between you and Danex Solution. By using our services, you accept these Terms and agree to use Danex Solution's services at your own risk. The laws of Nepal govern this Agreement, and by agreeing to these Terms, you submit to the exclusive jurisdiction.
          "
        />
        <TermsSection
          title="2. Binding Agreement:"
          content="These Terms constitute a binding agreement (the 'Agreement') between you and Danex Solution. By using our services, you accept these Terms and agree to use Danex Solution's services at your own risk. The laws of Nepal govern this Agreement, and by agreeing to these Terms, you submit to the exclusive jurisdiction."
        />
      </div>
    </div>
  );
};

const TermsSection = ({ title, content }) => {
  return (
    <section>
      <h2 className={sharedClasses.subHeading}>{title}</h2>
      <p className={sharedClasses.paragraph}>{content}</p>
    </section>
  );
};

export default TermsAndConditions;