import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './services.css';
import { servicesData } from './ServiceData';

export default function Services() {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const sectionRef = useRef(null); // Reference for the section div

  // Intersection Observer to detect when the section is in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visible state when the section is in view
          observer.disconnect(); // Stop observing once the section is visible
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => observer.disconnect(); // Clean up the observer on component unmount
  }, []);

  return (
    <div
      ref={sectionRef} // Attach ref to the section div
      className={`bg-[#3b5a82] py-20 ${isVisible ? 'animate-fadeInUp' : 'opacity-0'}`} // Apply fade-in animation if visible
      id="services"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center animate-fadeIn">
          <h2 className="text-base text-white font-semibold tracking-wide uppercase">Services</h2>
          <hr
            style={{
              margin: 'auto',
              width: '300px',
              marginTop: '10px',
              borderColor: 'white'
            }}
          />
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Check out the great services we offer
          </h3>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {servicesData.map((service, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6 animate-fadeIn hover-effect flex flex-col items-center">
                <div className="flex justify-center mb-4">
                  <img src={service.imgSrc} alt={service.altText} className="h-12 w-12 text-blue-500" />
                </div>
                <h4 className="text-xl font-semibold text-zinc-900 text-center">{service.title}</h4>
                <p className="mt-4 text-zinc-600">{service.description}</p>
                <button className="see-more rounded-lg"><Link to={service.link}>See More</Link></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
