import React from 'react';
import appdevelopment from './service_image/appdevelopment.jpg';
import 'animate.css';
import { Link } from 'react-router-dom';

const sharedClasses = {
  lightColor: 'text-gray-',
  lightBgColor: 'bg-white',
  hoverColor: 'hover:text-blue-500',
};

const AppDevelopment = () => {
  return (
    <div className={`${sharedClasses.lightBgColor} min-h-screen p-4 font-inter leading-tight`}>
      <div className="max-w-7xl mx-auto">
        <header className="text-center py-8">
          <h1 className={`${sharedClasses.lightColor}800 text-4xl font-semibold mb-4`}>App Development</h1>
          <nav className={`${sharedClasses.lightColor}500 text-base mt-2`}>
            <Link to='/' className={sharedClasses.hoverColor}>Home</Link> / <span>App Development</span>
          </nav>
        </header>
        <section className="mb-8">
          <img
            src={appdevelopment}
            alt="App Development"
            className="w-full h-auto rounded-lg shadow-lg animate__animated animate__fadeIn"
          />
        </section>
        <section className={`${sharedClasses.lightColor}700 px-4 text-lg`}>
          <div className="py-16">
            <p className="mb-4">
              At Danex Solution, we excel in providing comprehensive app development services designed to bring your ideas to life. Our team of skilled developers is dedicated to creating high-quality, user-friendly mobile applications that cater to the unique needs of our clients. We utilize the latest technologies to ensure that your app is both visually appealing and functionally robust.
            </p>
            <p>
              Understanding that each business is unique, we offer tailored solutions to meet your specific requirements. Whether you need a simple utility app or a complex enterprise solution, we have the expertise to deliver results that exceed your expectations.
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center mb-8 md:space-x-8">
            <img src={appdevelopment} alt="Our Services" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInLeft" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Our Services</h3>
              <p className="mb-4">
                We offer a comprehensive range of app development services designed to help you succeed in the mobile landscape. Our services include:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Custom Mobile App Development</li>
                <li>iOS and Android App Development</li>
                <li>Cross-Platform Development</li>
                <li>UI/UX Design</li>
                <li>App Maintenance and Support</li>
              </ul>
              <p>
                No matter the size or complexity of your project, our team is equipped to handle it with professionalism and expertise.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-8 md:space-x-8">
            <img src={appdevelopment} alt="Why Choose Us" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInRight" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Why Choose Us?</h3>
              <p className="mb-4">
                We believe in delivering quality solutions that drive success for our clients. Here’s why you should choose Danex Solution:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Expertise in the Latest Mobile Technologies</li>
                <li>Customized Solutions</li>
                <li>User-Friendly and Responsive Designs</li>
                <li>On-Time Delivery</li>
                <li>Ongoing Support and Maintenance</li>
              </ul>
              <p>
                Our goal is to build long-term relationships with our clients by providing reliable and effective app solutions.
              </p>
            </div>
          </div>
          <div className="md:space-x-8">
            <div className='pb-16'>
              <h3 className="text-xl font-extrabold mb-2">Get in Touch</h3>
              <p className="mb-4">
                Ready to take your mobile presence to the next level? <a href="#" className={sharedClasses.hoverColor}>Contact us</a> today to discuss your project and get a free quote! We are excited to work with you and help your business grow.
              </p>
              <p>
                At Danex Solution, we are passionate about app development and are dedicated to delivering exceptional results. Let us help you create an app that not only looks great but also performs exceptionally well.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AppDevelopment;
