import React from 'react';
// import Contact from '../Contact/contact';
import { Link } from 'react-router-dom';
import facebook from './facebook.png'
import instagram from './instagram.png'
import './footer.css'
// Shared Tailwind CSS classes
const textPrimary = 'text-blue-700';
const textSecondary = 'text-blue-500';
const footerContainer = 'container mx-auto px-4';
const footerSection = 'w-full md:w-1/4 mb-6 md:mb-0';
const footerHeading = 'font-bold text-lg';

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-100 text-gray-700 py-8">

        <div className={footerContainer}>
          <div className="flex justify-between custom-wrap">
            <div className="contact-footer">
              <iframe
                className="h-30 w-auto mr-4" // Changed from h-64 to h-96
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.226220218951!2d85.31885047525503!3d27.74116767616309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1900015944eb%3A0xfcf692a75f32663c!2sDanex%20Group!5e0!3m2!1sen!2snp!4v1717809269680!5m2!1sen!2snp"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className={footerSection}>
              <h2 className={footerHeading}>Danex Solution</h2>
              <p>P8RC+FH7, Tokha 44600<br />Nepal</p>
              <p><strong>Phone:</strong> +977 01-5920845<br /><strong>Email:</strong> info@danexsolution.com</p>
            </div>

            <div className={footerSection}>
              <h2 className={footerHeading}>Useful Links</h2>
              <ul>
                <li><Link to="/" className={textPrimary}>Home</Link></li>
                <li><Link to="/about" className={textPrimary}>About us</Link></li>
                <li><Link to="/services" className={textPrimary}>Services</Link></li>
                {/* <li><Link to="/terms" className={textPrimary}>Terms of Services</Link></li>
              <li><a href="#" className={textPrimary}>Privacy policy</a></li> */}
              </ul>
            </div>
            <div className={footerSection}>
              <h2 className={footerHeading}>Our Services</h2>
              <ul>
                <li><Link to="service/webdevelopment" className={textPrimary}>Web Development</Link></li>
                <li><Link to="service/appdevelopment" className={textPrimary}>App development</Link></li>
                <li><Link to="service/digitalmarketing" className={textPrimary}>Digital Marketing</Link></li>
                <li><Link to="service/graphicsdesign" className={textPrimary}>Graphics Design</Link></li>
                <li><Link to="service/videography" className={textPrimary}>Videography</Link></li>
                <li><Link to="service/seowriting" className={textPrimary}>SEO Writing</Link></li>
              </ul>
            </div>
            <div className={footerSection}>
              <h2 className={footerHeading}>Our Social Networks</h2>
              <p>Let's get connected on socials as well.</p>
              <div className="flex space-x-2">
                <a href="https://www.facebook.com/Danexsolution" className={textSecondary}><img className='h-8' src={facebook} alt="Facebook" /></a>
                <a href="https://www.instagram.com/danexsolution/" className={textSecondary}><img className='h-8' src={instagram} alt="Instagram" /></a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t pt-4 text-center text-sm">
            <p>&copy; Copyright <strong>Danex</strong>. All Rights Reserved</p>
            <p>Designed and Built by <a href="https://danexsolution.com/" className={textSecondary}>Danex Solution</a></p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
