import React from 'react';
import videography from './service_image/videography.png';
import 'animate.css';
import { Link } from 'react-router-dom';

const sharedClasses = {
  lightColor: 'text-gray-',
  lightBgColor: 'bg-white',
  hoverColor: 'hover:text-blue-500',
};

const Videography = () => {
  return (
    <div className={`${sharedClasses.lightBgColor} min-h-screen p-4 font-inter leading-tight`}>
      <div className="max-w-7xl mx-auto">
        <header className="text-center py-8">
          <h1 className={`${sharedClasses.lightColor}800 text-4xl font-semibold mb-4`}>Videography</h1>
          <nav className={`${sharedClasses.lightColor}500 text-base mt-2`}>
            <Link to='/' className={sharedClasses.hoverColor}>Home</Link> / <span>Videography</span>
          </nav>
        </header>
        <section className="mb-8">
          <img
            src={videography}
            alt="Videography"
            className="w-full h-auto rounded-lg shadow-lg animate__animated animate__fadeIn"
          />
        </section>
        <section className={`${sharedClasses.lightColor}700 px-4 text-lg`}>
          <div className="py-16">
            <p className="mb-4">
              At Danex Solution, we excel in providing high-quality videography services designed to capture your vision beautifully. Our team of experienced videographers is dedicated to creating visually stunning videos that tell your story and engage your audience. We utilize the latest equipment and techniques to ensure that your videos are not only visually appealing but also professionally crafted.
            </p>
            <p>
              We understand that each project is unique, which is why we offer customized videography solutions tailored to your specific needs. Whether you need a promotional video, event coverage, or a documentary, we have the expertise to deliver results that exceed your expectations.
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center mb-8 md:space-x-8">
            <img src={videography} alt="Our Services" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInLeft" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Our Services</h3>
              <p className="mb-4">
                We offer a comprehensive range of videography services designed to meet your specific needs. Our services include:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Corporate Videos</li>
                <li>Event Coverage</li>
                <li>Promotional Videos</li>
                <li>Documentary Filmmaking</li>
                <li>Video Editing and Post-Production</li>
              </ul>
              <p>
                No matter the size or complexity of your project, our team is equipped to handle it with professionalism and expertise.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-8 md:space-x-8">
            <img src={videography} alt="Why Choose Us" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInRight" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Why Choose Us?</h3>
              <p className="mb-4">
                We believe in delivering quality solutions that drive success for our clients. Here’s why you should choose Danex Solution:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Expertise in the Latest Videography Techniques</li>
                <li>Customized Solutions</li>
                <li>Attention to Detail</li>
                <li>On-Time Delivery</li>
                <li>Ongoing Support and Revisions</li>
              </ul>
              <p>
                Our goal is to build long-term relationships with our clients by providing reliable and effective videography solutions.
              </p>
            </div>
          </div>
          <div className="md:space-x-8">
            <div className='pb-16'>
              <h3 className="text-xl font-extrabold mb-2">Get in Touch</h3>
              <p className="mb-4">
                Ready to capture your vision on video? <a href="#" className={sharedClasses.hoverColor}>Contact us</a> today to discuss your project and get a free quote! We are excited to work with you and help your business grow.
              </p>
              <p>
                At Danex Solution, we are passionate about videography and are dedicated to delivering exceptional results. Let us help you create videos that not only look great but also effectively communicate your message.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Videography;