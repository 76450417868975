import React from 'react';
import graphicsdesign from './service_image/graphicsdesign.jpg';
import 'animate.css';
import { Link } from 'react-router-dom';

const sharedClasses = {
  lightColor: 'text-gray-',
  lightBgColor: 'bg-white',
  hoverColor: 'hover:text-blue-500',
};

const GraphicsDesign = () => {
  return (
    <div className={`${sharedClasses.lightBgColor} min-h-screen p-4 font-inter leading-tight`}>
      <div className="max-w-7xl mx-auto">
        <header className="text-center py-8">
          <h1 className={`${sharedClasses.lightColor}800 text-4xl font-semibold mb-4`}>Graphics Design</h1>
          <nav className={`${sharedClasses.lightColor}500 text-base mt-2`}>
            <Link to='/' className={sharedClasses.hoverColor}>Home</Link> / <span>Graphics Design</span>
          </nav>
        </header>
        <section className="mb-8">
          <img
            src={graphicsdesign}
            alt="Graphics Design"
            className="w-full h-auto rounded-lg shadow-lg animate__animated animate__fadeIn"
          />
        </section>
        <section className={`${sharedClasses.lightColor}700 px-4 text-lg`}>
          <div className="py-16">
            <p className="mb-4">
              At Danex Solution, we specialize in delivering exceptional graphics design services to enhance your brand’s visual identity. Our team of talented designers is dedicated to creating visually stunning designs that resonate with your audience. We combine creativity and technology to bring your ideas to life, ensuring that your brand stands out in the competitive market.
            </p>
            <p>
              We understand that each business has unique needs, which is why we offer customized design solutions tailored to your specific requirements. Whether you need a new logo, marketing materials, or a complete brand overhaul, we have the expertise to deliver designs that exceed your expectations.
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center mb-8 md:space-x-8">
            <img src={graphicsdesign} alt="Our Services" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInLeft" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Our Services</h3>
              <p className="mb-4">
                We offer a comprehensive range of graphics design services designed to help you communicate your brand effectively. Our services include:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Logo Design</li>
                <li>Brand Identity</li>
                <li>Marketing Materials</li>
                <li>Packaging Design</li>
                <li>Social Media Graphics</li>
              </ul>
              <p>
                No matter the size or complexity of your project, our team is equipped to handle it with creativity and expertise.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-8 md:space-x-8">
            <img src={graphicsdesign} alt="Why Choose Us" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInRight" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Why Choose Us?</h3>
              <p className="mb-4">
                We believe in delivering quality solutions that drive success for our clients. Here’s why you should choose Danex Solution:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Creative and Innovative Designs</li>
                <li>Customized Solutions</li>
                <li>Attention to Detail</li>
                <li>On-Time Delivery</li>
                <li>Ongoing Support and Revisions</li>
              </ul>
              <p>
                Our goal is to build long-term relationships with our clients by providing reliable and effective design solutions.
              </p>
            </div>
          </div>
          <div className="md:space-x-8">
            <div className='pb-16'>
              <h3 className="text-xl font-extrabold mb-2">Get in Touch</h3>
              <p className="mb-4">
                Ready to elevate your brand’s visual identity? <a href="#" className={sharedClasses.hoverColor}>Contact us</a> today to discuss your project and get a free quote! We are excited to work with you and help your business grow.
              </p>
              <p>
                At Danex Solution, we are passionate about graphics design and are dedicated to delivering exceptional results. Let us help you create designs that not only look great but also effectively communicate your brand’s message.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GraphicsDesign;
