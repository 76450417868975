import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Route and Navigate from react-router-dom
import './index.css';
import Header from './Component/Navbar/header';
import Mainlayout from './Component/Main/mainlayout';
import Footer from './Component/Footer/footer';
import Terms from './Component/Terms/terms';
// import Home from './Component/Home/home';
import DanexGroup from './Component/About/about';
import Services from './Component/Services/services';
import Portfolio from './Component/Portfolio/portfolio';
import Contact from './Component/Contact/contact';
import Error from './Component/Error/error';
import ScrollToTop from './Component/ScrollTop/scrolltop';
import WebDevelopment from './Component/Services/service/webdevelopment';
import AppDevelopment from './Component/Services/service/appdevelopment';
import GraphicsDesign from './Component/Services/service/graphicsdesgin';
import DigitalMarketing from './Component/Services/service/digitalmarketing';
import Videography from './Component/Services/service/videoediting';
import SEOWriting from './Component/Services/service/seo';

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Mainlayout />} />
                <Route path="about" element={<DanexGroup />} />
                <Route path="services" element={<Services />} />
                <Route path="service/webdevelopment" element={<WebDevelopment/>} />
                <Route path="service/appdevelopment" element={<AppDevelopment/>} />
                <Route path="service/graphicsdesign" element={<GraphicsDesign/>} />
                <Route path="service/digitalmarketing" element={<DigitalMarketing/>} />
                <Route path="service/videography" element={<Videography/>} />
                <Route path="service/seowriting" element={<SEOWriting/>} />
                <Route path="portfolio" element={<Portfolio />} />
                <Route path="contact" element={<Contact />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="*" element={<Error />} />
                <Route />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;