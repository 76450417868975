import React from 'react';
import './home.css';
import front from './2.png';

const Home = () => {
  return (
    <div className="home bg-zinc-100" id="home">
      <div className="texthome md:text-left">
        <h2 className="subhead fade-in-left w-fit">DIGITAL MARKETING AGENCY</h2>
        <h1 className="font-size font-bold text-custom-color mb-4 font-styles">
  <span>BOOST YOUR</span>
  <span>ONLINE</span>
  <span>PRESENCE!</span>
</h1>

        <p className="text-zinc-600 mb-6 custom-font-family-primary fade-in-up pr-[10px]">
          Increase your website traffic with our expert strategies. Get a free consultation!
        </p>
        <a href="#about" className="about-button">
        <button className="bg-custom-blue px-6 custom-py-3 c-marg rounded-lg">
  <span className="button-text">Get Started</span>
</button>

        </a>
      </div>
      <div className="image-box fade-in-down">
        <img
          src={front}
          alt="Rocket launching from a smartphone"
          className="md:h-auto max-w-[none] w-auto h-400 move-up-down image-height-responsive"
        />
      </div>
    </div>
  );
};

export default Home;
