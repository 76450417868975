import React from 'react';
import webdevelopment from './service_image/webdevelopment.jpg';
import web1 from './service_image/web1.jpg'
import web2 from './service_image/web2.jpg'
import 'animate.css';
import { Link } from 'react-router-dom';

const sharedClasses = {
  lightColor: 'text-gray-',
  lightBgColor: 'bg-white',
  hoverColor: 'hover:text-blue-500',
};

const WebDevelopment = () => {
  return (
    <div className={`${sharedClasses.lightBgColor} min-h-screen p-4 font-inter leading-tight`}>
      <div className="max-w-7xl mx-auto">
        <header className="text-center py-8">
          <h1 className={`${sharedClasses.lightColor}800 text-4xl font-semibold mb-4`}>Web Development</h1>
          <nav className={`${sharedClasses.lightColor}500 text-base mt-2`}>
            <Link to='/' className={sharedClasses.hoverColor}>Home</Link> / <span>Web Development</span>
          </nav>
        </header>
        <section className="mb-8">
          <img
            src={webdevelopment}
            alt="Web Development"
            className="w-full h-auto rounded-lg shadow-lg animate__animated animate__fadeIn"
          />
        </section>
        <section className={`${sharedClasses.lightColor}700 px-4 text-lg`}>
          <div className="py-16">
            <p className="mb-4">
              At Danex Solution, we specialize in providing top-notch web development services to help businesses establish a strong online presence. Our team of experienced developers is dedicated to creating high-quality, responsive websites that cater to the unique needs of our clients. We leverage the latest technologies to ensure that your website is not only visually appealing but also functionally robust.
            </p>
            <p>
              We understand that every business is different, which is why we offer customized solutions tailored to meet your specific requirements. Whether you need a simple informational site or a complex e-commerce platform, we have the expertise to deliver results that exceed your expectations.
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center mb-8 md:space-x-8">
            <img src={web2} alt="Our Services" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInLeft" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Our Services</h3>
              <p className="mb-4">
                We offer a comprehensive range of web development services designed to help you succeed online. Our services include:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Custom Website Development</li>
                <li>E-commerce Solutions</li>
                <li>Content Management Systems (CMS)</li>
                <li>Responsive Web Design</li>
                <li>Website Maintenance and Support</li>
              </ul>
              <p>
                No matter the size or complexity of your project, our team is equipped to handle it with professionalism and expertise.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center mb-8 md:space-x-8">
            <img src={web1} alt="Why Choose Us" className="w-full md:w-1/2 rounded-lg shadow-lg mb-4 md:mb-0 animate__animated animate__fadeInRight" />
            <div className="md:w-1/2">
              <h3 className="text-xl font-extrabold mb-2">Why Choose Us?</h3>
              <p className="mb-4">
                We believe in delivering quality solutions that drive success for our clients. Here’s why you should choose Danex Solution:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Expertise in the Latest Web Technologies</li>
                <li>Customized Solutions</li>
                <li>User-Friendly and Responsive Designs</li>
                <li>On-Time Delivery</li>
                <li>Ongoing Support and Maintenance</li>
              </ul>
              <p>
                Our goal is to build long-term relationships with our clients by providing reliable and
                effective web solutions.
              </p>
            </div>
          </div>
          <div className="md:space-x-8">
            <div className='pb-16'>
              <h3 className="text-xl font-extrabold mb-2">Get in Touch</h3>
              <p className="mb-4">
                Ready to take your online presence to the next level? <a href="#" className={sharedClasses.hoverColor}>Contact us</a> today to discuss your project and get a free quote! We are excited to work with you and help your business grow.
              </p>
              <p>
                At Danex Solution, we are passionate about web development and are dedicated to delivering exceptional results. Let us help you create a website that not only looks great but also performs exceptionally well.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WebDevelopment;
