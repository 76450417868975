import React, { useState, useEffect, useRef } from 'react';
import './portfolio.css';

// Portfolio items data
const PORTFOLIO_ITEMS = [
  { id: 1, image: 'portfolioimg/floweraura.png', title: 'Flower Aura', category: 'Ecommerce', link: 'https://www.floweraura.com/' },
  { id: 2, image: 'portfolioimg/thomsonreuters.png', title: 'Thomson Reuter', category: 'Business', link: 'https://www.thomsonreuters.com/en.html' },
  { id: 3, image: 'portfolioimg/bakingo.png', title: 'Bakingo', category: 'Ecommerce', link: 'https://www.bakingo.com/' },
  { id: 4, image: 'portfolioimg/arcelormittal.png', title: 'Arcelor Mittal', category: 'Business', link: 'https://corporate.arcelormittal.com/' },
  { id: 5, image: 'portfolioimg/zs.png', title: 'ZS', category: 'Consulting', link: 'https://www.zs.com/' },
  { id: 6, image: 'portfolioimg/futurelink.png', title: 'Future Link Consultants', category: 'Consulting', link: 'https://futurelinkconsultants.com/' }
];

// Shared class names for styling consistency
const sharedClassNames = {
  textZinc: 'text-zinc-600',
  textBlue: 'text-blue-600',
  bgZinc: 'bg-zinc-100',
  textZincDark: 'text-zinc-800',
  container: 'container mx-auto px-5',
  grid: 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6',
  relative: 'relative group',
  image: 'w-full h-auto rounded-lg shadow-md',
  overlay: 'absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity',
  link: 'text-white text-lg font-semibold'
};

// PortfolioItem component that includes visibility animation
const PortfolioItem = ({ id, image, title, link }) => {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const itemRef = useRef(null); // Reference to the DOM element

  // Use the Intersection Observer API to detect when the item is visible in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set the item as visible when it appears in the viewport
          observer.disconnect(); // Stop observing once the item is visible
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the item is visible
      }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current); // Start observing the item
    }

    return () => observer.disconnect(); // Clean up the observer on component unmount
  }, []);

  return (
    <div
      ref={itemRef} // Attach the ref to the container div
      className={`${sharedClassNames.relative} ${isVisible ? 'animate-fadeInUp' : 'opacity-0'}`} // Apply animation if visible
      style={{ transition: 'opacity 0.5s ease, transform 0.5s ease' }} // Smooth transition for visibility
    >
      <img src={image} alt={`Portfolio Image ${id}`} className={sharedClassNames.image} />
      <div className={sharedClassNames.overlay}>
        <a href={link} className={sharedClassNames.link} target="_blank" rel="noopener noreferrer">{title}</a>
      </div>
    </div>
  );
};

// Portfolio component with category filtering and animations
const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('All'); // State for selected category
  const [isVisible, setIsVisible] = useState(false); // State for section visibility
  const sectionRef = useRef(null); // Reference to the portfolio section

  // Use the Intersection Observer API to detect when the portfolio section is visible in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set the section as visible when it appears in the viewport
          observer.disconnect(); // Stop observing once the section is visible
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Start observing the section
    }

    return () => observer.disconnect(); // Clean up the observer on component unmount
  }, []);

  // Handle category selection
  const handleCategoryClick = (event, category) => {
    event.preventDefault();
    setSelectedCategory(category);
  };

  // Filter portfolio items based on the selected category
  const filteredItems = selectedCategory === 'All' ? PORTFOLIO_ITEMS : PORTFOLIO_ITEMS.filter(item => item.category === selectedCategory);

  return (
    <div
      ref={sectionRef} // Attach the ref to the portfolio section div
      className={`${sharedClassNames.bgZinc} py-20 ${isVisible ? 'animate-fadeInUp' : 'opacity-0'}`} // Apply animation if visible
      id='portfolio'
    >
      <div className="text-center">
        <h2 className="text-base text-black font-semibold tracking-wide uppercase">Portfolio</h2>
        <hr
          style={{
            margin: 'auto',
            width: '300px',
            marginTop: '10px',
            borderColor: 'black'
          }}
        />
        <h3 className="mt-2 text-3xl pb-16 leading-8 font-extrabold tracking-tight text-black sm:text-4xl">
          Check out our beautiful portfolios
        </h3>
      </div>
      <div className="flex justify-center mb-6">
        <nav className="space-x-4">
          {['All', 'Ecommerce', 'Consulting', 'Business'].map(category => (
            <a
              href="#"
              key={category}
              className={category === selectedCategory ? sharedClassNames.textBlue : sharedClassNames.textZinc}
              onClick={(event) => handleCategoryClick(event, category)}
            >
              {category}
            </a>
          ))}
        </nav>
      </div>
      <div className={`${sharedClassNames.container} ${sharedClassNames.grid}`}>
        {filteredItems.map(item => (
          <PortfolioItem key={item.id} id={item.id} image={item.image} title={item.title} link={item.link} />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
