import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import danexSolution from './danexSolution.png';
import './navbar.css';

export default function Navbar() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const timerRef = useRef(null);
  const location = useLocation();

  const showDropdown = () => {
    clearTimeout(timerRef.current);
    setDropdownVisible(true);
  };

  const hideDropdown = () => {
    timerRef.current = setTimeout(() => {
      setDropdownVisible(false);
    }, 300);
  };

  useEffect(() => {
    const dropdown = document.querySelector('.dropdown');
    dropdown.addEventListener('mouseover', showDropdown);
    dropdown.addEventListener('mouseleave', hideDropdown);
    return () => {
      dropdown.removeEventListener('mouseover', showDropdown);
      dropdown.removeEventListener('mouseleave', hideDropdown);
    };
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <input type="checkbox" id="toggle" className="input" />
      <nav className="navbar">
        <div className="nav_container">
          <div className="logo">
            <Link to='/'><img src={danexSolution} alt="danex solution" /></Link>
          </div>
          <label htmlFor="toggle" className="navbar-toggler">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </label>
          <ul className="nav" id="navl">
            <li><Link to="/" className={isActive('/') ? 'active' : ''} >Home</Link></li>
            <li><Link to="/about" className={isActive('/about') ? 'active' : ''}>About</Link></li>
            <li className="dropdown">
              <Link to="/services" className={isActive('/services') ? 'active' : ''}>Services</Link>
              <ul className={`dropdown-content ${dropdownVisible ? 'show' : ''}`}>
                <li><Link to="/service/webdevelopment" className={isActive('/service/webdevelopment') ? 'active' : ''}>Web Development</Link></li>
                <li><Link to="/service/appdevelopment" className={isActive('/service/appdevelopment') ? 'active' : ''}>App Development</Link></li>
                <li><Link to="/service/graphicsdesign" className={isActive('/service/graphicsdesign') ? 'active' : ''}>Graphics Design</Link></li>
                <li><Link to="/service/digitalmarketing" className={isActive('/service/digitalmarketing') ? 'active' : ''}>Digital Marketing</Link></li>
                <li><Link to="/service/videography" className={isActive('/service/videography') ? 'active' : ''}>Videography</Link></li>
                <li><Link to="/service/seowriting" className={isActive('/service/seowriting') ? 'active' : ''}>SEO Writing</Link></li>
              </ul>
            </li>
            <li><Link to="/portfolio" className={isActive('/portfolio') ? 'active' : ''}>Portfolio</Link></li>
            <li><Link to="/contact" className={isActive('/contact') ? 'active' : ''}>Contact</Link></li>
          </ul>
        </div>
      </nav>
      <br />
    </>
  );
}
