import React from 'react';
import './contact.css';

const ContactInfo = ({ iconSrc, altText, title, content }) => {
  return (
    <div className="flex items-start mb-4">
      <div className="flex-shrink-0">
        <img src={iconSrc} alt={altText} className="rounded-full bg-blue-100 p-2 h-10" />
      </div>
      <div className="ml-4">
        <h3 className="text-lg font-bold text-[#4e4039]">{title}</h3>
        <p className="text-zinc-500">{content}</p>
      </div>
    </div>
  );
};

const MapSection = () => {
  return (
    <div className="overflow-hidden rounded-lg shadow-md my-16">
      <iframe
        className="w-full h-96" // Changed from h-64 to h-96
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.226220218951!2d85.31885047525503!3d27.74116767616309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1900015944eb%3A0xfcf692a75f32663c!2sDanex%20Group!5e0!3m2!1sen!2snp!4v1717809269680!5m2!1sen!2snp"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

const Contact = () => {
  const containerClasses = "max-w-4xl mx-auto py-20";
  const cardClasses = "bg-white shadow-2xl rounded-lg p-6 mb-8 custom-border";

  return (
    <div className={containerClasses} id='contact'>
      <div className="text-center animate-fadeIn">
        <h2 className="text-base text-black font-semibold tracking-wide uppercase">Contact Us</h2>
        <hr
      style={{
        margin: 'auto',   
        width: '300px',
        marginTop: '10px',   
        borderColor: 'black'  
      }}
    />
        <h3 className="mt-2 text-3xl pb-16 leading-8 font-extrabold tracking-tight text-black sm:text-4xl">
          Contact us to get started
        </h3>
      </div>
      <div className={cardClasses}>
        <ContactInfo
          iconSrc="contacticon/location.png"
          altText="Location Icon"
          title="Location:"
          content="P8RC+FH7, Tokha 44600"
        />
        <ContactInfo
          iconSrc= "contacticon/email.png"
          altText="Email Icon"
          title="Email:"
          content="info@danexsolution.com"
        />
        <ContactInfo
          iconSrc="contacticon/phone.png"
          altText="Phone Icon"
          title="Call:"
          content="+977 01-5920845"
        />
      <MapSection />
      </div>
    </div>
  );
};

export default Contact;
